import React from 'react';
import Image from './Image';

const Icon = ({ name, ...others }) => {

    return (
        <Image name={name} {...others} basePath='icons/' />
    )
}

export default Icon
