import * as React from "react"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import withStyles from "@material-ui/core/styles/withStyles"
import capitalize from "@material-ui/core/utils/capitalize"

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    margin: 0,
    "& span": {
      display: "inline-block",
    },
  },
  overline: {
    "&:before": {
      content: '""',
      width: 50,
      display: "block",
      height: 4,
      borderRadius: 2,
      backgroundColor: theme.customPalette.darkishPurple,
      marginBottom: theme.spacing(3),
    },
  },
  weightBold: theme.text.bold,
  weightMedium: theme.text.medium,
  weightNormal: theme.text.normal,
  /* Styles applied to the root element if `variant="specialTitleXXL"`. */
  specialTitleXXL: theme.text.specialTitleXXL,
  /* Styles applied to the root element if `variant="specialTitleXL"`. */
  specialTitleXL: theme.text.specialTitleXL,
  /* Styles applied to the root element if `variant="specialTitleL"`. */
  specialTitleL: theme.text.specialTitleL,
  /* Styles applied to the root element if `variant="titleExtXXXL"`. */
  titleExtXXXL: theme.text.titleExtXXXL,
  /* Styles applied to the root element if `variant="titleXXXL"`. */
  titleXXXL: theme.text.titleXXXL,
  /* Styles applied to the root element if `variant="titleXXL"`. */
  titleXXL: theme.text.titleXXL,
  /* Styles applied to the root element if `variant="titleExtXL"`. */
  titleExtXL: theme.text.titleExtXL,
  /* Styles applied to the root element if `variant="titleXL"`. */
  titleXL: theme.text.titleXL,
  /* Styles applied to the root element if `variant="titleL"`. */
  titleL: theme.text.titleL,
  /* Styles applied to the root element if `variant="titleL"`. */
  title: theme.text.title,
  /* Styles applied to the root element if `variant="bodyExtXXL"`. */
  bodyExtXXL: theme.text.bodyExtXXL,
  /* Styles applied to the root element if `variant="bodyXXL"`. */
  bodyXXL: theme.text.bodyXXL,
  /* Styles applied to the root element if `variant="bodyXL"`. */
  bodyXL: theme.text.bodyXL,
  /* Styles applied to the root element if `variant="bodyExtL"`. */
  bodyExtL: theme.text.bodyExtL,
  /* Styles applied to the root element if `variant="bodyL"`. */
  bodyL: theme.text.bodyL,
  /* Styles applied to the root element if `variant="bodyExtM"`. */
  bodyExtM: theme.text.bodyExtM,
  /* Styles applied to the root element if `variant="bodyM"`. */
  bodyM: theme.text.bodyM,
  /* Styles applied to the root element if `variant="bodyExtS"`. */
  bodyLhM: theme.text.bodyLhM,
  /* Styles applied to the root element if `variant="bodyM"`. */
  bodyExtS: theme.text.bodyExtS,
  /* Styles applied to the root element if `variant="bodyS"`. */
  bodyS: theme.text.bodyS,
  /* Styles applied to the root element if `variant="bodyXS"`. */
  bodyXS: theme.text.bodyXS,
  /* Styles applied to the root element if `variant="bodyXS"`. */
  bodyXXS: theme.text.bodyXXS,
  /* Styles applied to the root element if `variant="srOnly"`. Only accessible to screen readers. */
  srOnly: {
    position: "absolute",
    height: 1,
    width: 1,
    overflow: "hidden",
  },
  /* Styles applied to the root element if `align="left"`. */
  alignLeft: {
    textAlign: "left",
  },
  /* Styles applied to the root element if `align="center"`. */
  alignCenter: {
    textAlign: "center",
    "&:before": {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  /* Styles applied to the root element if `align="right"`. */
  alignRight: {
    textAlign: "right",
  },
  /* Styles applied to the root element if `align="justify"`. */
  alignJustify: {
    textAlign: "justify",
  },
  /* Styles applied to the root element if `verticalAlign="middle"`. */
  verticalAlignMiddle: {
    margin: "auto",
  },
  /* Styles applied to the root element if `nowrap={true}`. */
  noWrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  /* Styles applied to the root element if `gutterBottom={true}`. */
  gutterBottom: {
    marginBottom: "0.35em",
  },
  /* Styles applied to the root element if `paragraph={true}`. */
  paragraph: {
    marginBottom: 16,
  },
  /* Styles applied to the root element if `color="inherit"`. */
  colorInherit: {
    color: "inherit",
  },
  /* Styles applied to the root element if `color="primary"`. */
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  /* Styles applied to the root element if `color="secondary"`. */
  colorSecondary: {
    color: theme.palette.secondary.main,
  },
  /* Styles applied to the root element if `color="textPrimary"`. */
  colorTextPrimary: {
    color: theme.palette.text.primary,
  },
  /* Styles applied to the root element if `color="textSecondary"`. */
  colorTextSecondary: {
    color: theme.palette.text.secondary,
  },
  /* Styles applied to the root element if `color="textTertiary"`. */
  colorTextTertiary: {
    color: theme.palette.text.tertiary,
  },
  /* Styles applied to the root element if `color="error"`. */
  colorError: {
    color: theme.palette.error.main,
  },
  /* Styles applied to the root element if `display="inline"`. */
  displayInline: {
    display: "inline",
  },
  /* Styles applied to the root element if `display="block"`. */
  displayBlock: {
    display: "block",
  },
})

const defaultVariantMapping = {
  specialTitleXXL: "h1",
  specialTitleXL: "h2",
  specialTitleL: "h2",
  titleExtXXXL: "h2",
  titleXXXL: "h2",
  titleXXL: "h3",
  titleExtXL: "h3",
  titleXL: "h4",
  titleL: "h5",
  title: "p",
  bodyExtXXL: "p",
  bodyXXL: "p",
  bodyXL: "p",
  bodyExtL: "p",
  bodyL: "p",
  bodyExtM: "p",
  bodyM: "p",
  bodyLhM: "p",
  bodyExtS: "p",
  bodyS: "p",
  bodyXS: "p",
  bodyXXS: "p",
}

const Text = React.forwardRef((props, ref) => {
  const {
    align = "inherit",
    verticalAlign = "inherit",
    classes,
    className,
    color = "initial",
    component,
    display = "initial",
    overline = false,
    gutterBottom = false,
    noWrap = false,
    paragraph = false,
    weight = "initial",
    variant = "body1",
    variantMapping = defaultVariantMapping,
    children,
    ...other
  } = props

  let tag = variantMapping[variant]

  if (children && typeof children === "string") {
    // FIX to avoid having <p> inside <p>, which isn't valid HTML and therefore isn't rendered
    if (variantMapping[variant] === "p" && children.includes("</p>"))
      tag = "span"

    // FIX to avoid having <ul> or <ol> inside <p>, which isn't valid HTML and therefore isn't rendered
    if (children.includes("</ul>") || children.includes("</ol>")) tag = "div"
  }

  switch (tag) {
    case "div":
      return (
        <div
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )
    case "p":
      return (
        <p
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    case "span":
      return (
        <span
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    case "h1":
      return (
        <h1
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    case "h2":
      return (
        <h2
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    case "h2":
      return (
        <h2
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    case "h3":
      return (
        <h3
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    case "h4":
      return (
        <h4
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    case "h5":
      return (
        <h5
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    case "h6":
      return (
        <h6
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )

    default:
      return (
        <span
          className={clsx(
            classes.root,
            {
              [classes[variant]]: variant !== "inherit",
              [classes[`color${capitalize(color)}`]]: color !== "initial",
              [classes.noWrap]: noWrap,
              [classes.overline]: overline,
              [classes.gutterBottom]: gutterBottom,
              [classes.paragraph]: paragraph,
              [classes[`weight${capitalize(weight)}`]]: weight !== "initial",
              [classes[`verticalAlign${capitalize(verticalAlign)}`]]:
                verticalAlign !== "inherit",
              [classes[`align${capitalize(align)}`]]: align !== "inherit",
              [classes[`display${capitalize(display)}`]]: display !== "initial",
            },
            className
          )}
          ref={ref}
          dangerouslySetInnerHTML={{ __html: children }}
          {...other}
        />
      )
  }
})

Text.propTypes = {
  /**
   * Set the text-align on the component.
   */
  align: PropTypes.oneOf(["inherit", "left", "center", "right", "justify"]),

  verticalAlign: PropTypes.oneOf(["middle"]),
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf([
    "initial",
    "inherit",
    "primary",
    "secondary",
    "textPrimary",
    "textSecondary",
    "textTertiary",
    "error",
  ]),
  /**
   * The component used for the root node.
   * Either a string to use a HTML element or a component.
   * Overrides the behavior of the `variantMapping` prop.
   */
  component: PropTypes.elementType,
  /**
   * Controls the display type
   */
  display: PropTypes.oneOf(["initial", "block", "inline"]),
  /**
   * If `true`, shows the overline.
   */
  overline: PropTypes.bool,
  /**
    /**
     * If `true`, the text will have a bottom margin.
     */
  gutterBottom: PropTypes.bool,
  /**
   * If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.
   *
   * Note that text overflow can only happen with block or inline-block level elements
   * (the element needs to have a width in order to overflow).
   */
  noWrap: PropTypes.bool,
  /**
   * If `true`, the text will have a bottom margin.
   */
  paragraph: PropTypes.bool,

  /**
   * Controls the font weight
   */
  weight: PropTypes.oneOf(["bold", "medium", "normal"]),
  /**
   * Applies the theme text styles.
   */
  variant: PropTypes.oneOf([
    "specialTitleXXL",
    "specialTitleXL",
    "titleExtXXXL",
    "titleXXXL",
    "titleXXL",
    "titleExtXL",
    "titleXL",
    "titleL",
    "title",
    "bodyExtXXL",
    "bodyXXL",
    "bodyXL",
    "bodyExtL",
    "bodyL",
    "bodyExtM",
    "bodyM",
    "bodyLhM",
    "bodyExtS",
    "bodyS",
    "bodyXS",
    "bodyXXS",
  ]),
  /**
   * The component maps the variant prop to a range of different HTML element types.
   * For instance, subtitle1 to `<h6>`.
   * If you wish to change that mapping, you can provide your own.
   * Alternatively, you can use the `component` prop.
   */
  variantMapping: PropTypes.object,
}

export default withStyles(styles, { name: "FHText" })(Text)
