import React, { Component } from "react"

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

const initialState = {
  cookieBar: {
    show: false,
    height: 0,
  },
  cta: {
    open: false,
    productCode: "",
    productVersion: "",
  },
  earlybirdOffer: {
    expirationDate: null,
    expirationText: "",
    show: false,
  },
  loginGProxy: {
    userName: "",
  },
  newsletter: {
    text: "",
  },
  headerInfo: {
    differentCountry: 0,
    topbar: 0,
    navbar: 0,
    total: 0,
  },
}

function getAccountInfo(state, payload) {
  return {
    userName: payload.userName,
  }
}
function getHeaderInfo(state, payload) {
  return {
    differentCountry: payload.differentCountry,
    topbar: payload.topbar,
    navbar: payload.navbar,
    total: payload.total,
  }
}
function getNewsletterInfo(state, payload) {
  return {
    text: payload.text,
  }
}

function updateCookieBar(state, payload) {
  return {
    show: payload.show,
    height: payload.height,
  }
}

function openCta(state, payload) {
  if (state && state.cta) {
    return {
      open: payload && payload.force ? false : !state.cta.open,
      productCode: payload ? payload.productCode : "",
      //HOTFIX 26-07
      force: payload ? payload.force : false,
      productVersion: payload ? payload.productVersion : "",
    }
  } else {
    return { open: false, productCode: "", force: false }
  }
}

function showEarlybirdOffer(state, payload) {
  return {
    expirationDate: payload.expirationDate,
    expirationText: payload.expirationText,
    show: payload.show,
  }
}

function reducer(state, action) {
  switch (action.type) {
    case "updateCookieBar": {
      return {
        ...state,
        cookieBar: updateCookieBar(state, action.payload),
      }
    }
    case "getHeaderInfo": {
      return {
        ...state,
        headerInfo: getHeaderInfo(state, action.payload),
      }
    }
    case "getAccountInfo": {
      return {
        ...state,
        loginGProxy: getAccountInfo(state, action.payload),
      }
    }
    case "getNewsletterInfo": {
      return {
        ...state,
        newsletter: getNewsletterInfo(state, action.payload),
      }
    }
    case "toggleCta": {
      return {
        ...state,
        cta: openCta(state, action.payload),
      }
    }
    case "showEarlybirdOffer": {
      return {
        ...state,
        earlybirdOffer: showEarlybirdOffer(state, action.payload),
      }
    }
    default: {
      throw new Error("Bad Action Type")
    }
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider
