import React from "react"
import { Helmet } from 'react-helmet';

const FontsPreload = () => {

    return (
        <Helmet>
            {/* preload fonts (avoid to use sync font load from gatsby plugin) */}
            <link as="style" rel="preload" href="/fonts/spezia/fonts.css" />
            <link as="style" rel="preload" href="/fonts/drunk/fonts.css" />
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap" rel="stylesheet" />
        </Helmet>
    )
}

export default FontsPreload