/**
 * Lazy Loader for DeA Tech `Ghost` Scripts library...
 */

import { window, document } from "browser-monads"

/** gproxy react support */
const ghost____fetcher = async (js, url) => {
  return await fetch(url).then(value => {
    return value.text().then(content => {
      const script = document.createElement("script")
      script.textContent = content
      document.head.appendChild(script)
      script.src = url
      return new Promise(fetcher[js].initialize)
    })
  })
}

const ghost____loader = async (js, url) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.onload = function () {
      fetcher[js].initialize(resolve, reject)
    }
    script.src = url
    document.head.appendChild(script)
  })
}

/** fetcher mapping */
var fetcher = {
  gproxy: {
    url: process.env.GATSBY_GPROXY_URL,
    test: () => {
      return (
        typeof window.gproxy !== "undefined" &&
        typeof window.gproxy.session !== "undefined"
      )
    },
    initialize: (resolve, reject) => {
      console.debug("[Ghost] gproxy auto-init...")
      window.gproxy &&
        window.gproxy(false, function (event) {
          console.debug("[Ghost] gproxy auto-init...OK", window.gproxy)
          resolve({ result: true, target: window.gproxy, event: event })
        })
    },
    redo: (resolve, reject) => {
      resolve({ result: true, target: window.gproxy })
    },
  },
}

/** ghost scripts common name apis */
export const ghost = (js, url) => {
  if (fetcher[js].test()) {
    //console.debug(`[Ghost] Script ${js} already initialized...redo!`);
    return new Promise(fetcher[js].redo)
  }
  return ghost____loader(js, url ? url : fetcher[js].url)
}
