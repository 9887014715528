import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const Image = ({ name, basePath, ...others }) => {
  const data = useStaticQuery(graphql`
    query {
        mobileImages: allFile(filter: {relativePath: {regex: "/[_0-9A-Za-z/]*.png/i"}}) {
          nodes {
            name
            relativePath
            publicURL
          }
        }
        tabletImages: allFile(filter: {relativePath: {regex: "/[_0-9A-Za-z/]*@2x.png/i"}}) {
          nodes {
            name
            relativePath
            publicURL
          }
        }
        desktopImages: allFile(filter: {relativePath: {regex: "/[_0-9A-Za-z/]*@3x.png/i"}}) {
          nodes {
            name
            relativePath
            publicURL
          }
        }
      }
  `);

  const regexName = new RegExp(`^${name}$|^${name}@2x|^${name}@3x`, 'i');
  const regexPath = new RegExp(`^${basePath}${name}`, 'i');

  const match = useMemo(() => {
    // Move dependancy func inside to avoid exhaustive-deps warning
    const filter = (img) => img.name.match(regexName) && img.relativePath.match(regexPath);
    return {
      img: data.mobileImages.nodes.find(filter).publicURL,
      img2x: data.tabletImages.nodes.find(filter).publicURL,
      img3x: data.desktopImages.nodes.find(filter).publicURL
    }
  }, [data, regexName, regexPath]);

  return (
    <img
      alt=''
      srcSet={`${match.img} 300w, ${match.img2x} 768w, ${match.img3x} 1280w`}
      {...others}
    />
  )
}
Image.defaultProps = {
  basePath: ''
}

export default Image
