import React, { useState, useRef } from "react"
import SEO from "../../components/SEO/SEO"
import Preload from "../preload"
import { ParallaxProvider } from "react-scroll-parallax"
import GlobalContextProvider from "@src/context/GlobalContextProvider"
import Icon from "../../components/Icon"
import { makeStyles } from "@material-ui/core/styles"
import { window } from "browser-monads"

const useStyles = makeStyles(theme => ({
  arrow: {
    position: "fixed",
    top: "55%",
    zIndex: "100",
    right: "20px",
    cursor: "pointer",
    backgroundColor: "#662482",
    width: "56px",
    height: "56px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    transform: "rotate(-180deg)",
    border: "1px solid white",
  },
}))

const DefaultTemplate = ({ children, page, preview, templateLabel }) => {
  const [visibleSrcoll, setVisibleScroll] = useState(false)
  const containerRef = useRef(null)
  const classes = useStyles()
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
  const toggleVisible = () => {
    const element = containerRef.current
    const scrolled = document.documentElement.scrollTop
    if (scrolled > element.clientHeight / 2) {
      setVisibleScroll(true)
    } else if (scrolled <= element.clientHeight / 2) {
      setVisibleScroll(false)
    }
  }
  window.addEventListener("scroll", toggleVisible)
  return (
    <GlobalContextProvider>
      <Preload />
      <ParallaxProvider>
        <div style={{ overflow: "visible" }} ref={containerRef}>
          {visibleSrcoll && (
            <Icon
              className={classes.arrow}
              onClick={scrollToTop}
              name="scroll-to-top"
            />
          )}
          <SEO
            {...page.seo}
            {...page.tracking}
            social={page.social}
            product_info={page.product_info}
            qa_exception={page.qa_exception}
          />
          {page.content}
          {children}
        </div>
      </ParallaxProvider>
    </GlobalContextProvider>
  )
}

export default DefaultTemplate
